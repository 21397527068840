<template>
  <div class=" font-test" v-show="['zh_tw'].includes(language)">
    <div class="nav-box">
      <div class="top-menu-box" style="width:1000px;margin:0 auto">
        <div class="container top-menu">
          <!-- 左边logo -->
          <div class="left-logo">
            <el-image :src="logo" class="logo-img" lazy @click="pageHandle('/home')"></el-image>
          </div>
          <!-- 右边菜单 -->
        </div>
      </div>
    </div>
    <div class="content-box">
      <el-image :src="header" class="header" lazy  @load="imgOnload">
        <div slot="placeholder" v-if="showBtn">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>
      <div class="header-href">
        <a href="https://www.facebook.com/travelcontents" class="main-fb" target="_blank"></a>
        <a href="https://www.instagram.com/p/CdmmN5lr84J/" class="main-ig" target="_blank"></a>
      </div>
      <div class="main-box">
        <a href="https://www.facebook.com/travelcontents" class="main-FB" target="_blank"></a>
        <a href="https://www.instagram.com/travelcontents.tw/" class="main-IG" target="_blank"></a>
        <a href="https://www.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q/featured/" class="main-YT" target="_blank"></a>
        <a href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553"  class="apple" target="_blank"></a>
        <a v-if="language != 'zh'" href="https://play.google.com/store/apps/details?id=com.tripellet.app" class="google" target="_blank"></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showBtn: false,
      logo: require("@/assets/images/customer/PC-home.png"),
      header:require('@/assets/images/draw/PC.jpg'),
    }
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  mounted(){
    // 进入页面回到顶部
    this.$router.afterEach((to,from,next)=>{
      window.scrollTo(0,0)
    })
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } })
      } else {
        this.$router.push(path)
      }
    },
    imgOnload() {
      this.showBtn = true
      this.$forceUpdate()
    }
  },
}
</script>
<style lang="scss" scoped>
.font-test {
  font-family: "GenJyuuGothic";
}
.app-container {
  width: 90%;
  margin: 0 auto;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
}
.button-box {
  position: absolute;
  top: 62.2%;
  left: 52.8%;
  z-index: 999;
  .el-image {
    width: 19%;
    margin: 0 5px;
  }
}
.nav-box {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}
.top-menu-box {
  background: #fff;
}
.logo-img {
  width: 30px;
  cursor: pointer;
}
.header{
  width: 100%;
  display: block;
  image-rendering: -moz-crisp-edges;/* Firefox */
  image-rendering: -o-crisp-edges;/* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;/* IE (non-standard property) */
}

@media screen and (min-width: 1441px) and (max-width: 1920px){
  .main-fb{
    position: absolute;
    left: 37.5%;
    top: 43.7%;
    width: 90px;
    height: 72px; 
    display: block;
  }
  .main-ig{
    position: absolute;
    left: 37.5%;
    top: 50.5%;
    width: 90px;
    height: 72px;
    display: block;
  }
  .main-FB{
    position: absolute;
    bottom: 13.3%;
    left: 41%;
    width: 90px;
    height: 72px;
    display: block;
  }
  .main-IG{
    position: absolute;
    bottom: 13.3%;
    left: 47.5%;
    width: 90px;
    height: 72px;
    display: block;
  }
  .main-YT{
    position: absolute;
    bottom: 13.3%;
    left: 53.7%;
    width: 90px;
    height: 72px;
    display: block;
  }
  .apple{
    position: absolute;
    left: 43.2%;
    bottom: 130px;
    width: 171px;
    height: 48px;
  }
  .google{
    position: absolute;
    left: 53.2%;
    bottom: 130px;
    width: 171px;
    height: 48px;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px){
  .content-box{
    position: relative;
    width: 100%;
    height: auto;
  }
  .main-fb{
    position: absolute;
    left: 36.5%;
    top: 43.2%;
    width: 50px;
    height: 50px; 
    display: block;
  }
  .main-ig{
    position: absolute;
    left: 36.5%;
    top: 49.5%;
    width: 50px;
    height: 50px;
    display: block;
  }
  .main-FB{
    position: absolute;
    bottom: 12.6%;
    left: 41%;
    width: 60px;
    height: 50px;
    display: block;
  }
  .main-IG{
    position: absolute;
    bottom: 12.6%;
    left: 47.5%;
    width: 60px;
    height: 50px;
    display: block;
  }
  .main-YT{
    position: absolute;
    bottom: 12.6%;
    left: 53.7%;
    width: 60px;
    height: 50px;
    display: block;
  }
  .apple{
    position: absolute;
    left: 43.2%;
    bottom: 42px;
    width: 70px;
    height: 40px;
  }
  .google{
    position: absolute;
    left: 53.2%;
    bottom: 42px;
    width: 70px;
    height: 40px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .content-box{
    position: relative;
    width: 100%;
    height: auto;
  }
    .main-fb{
    position: absolute;
    left: 37%;
    top: 43.4%;
    width: 50px;
    height: 50px; 
    display: block;
  }
  .main-ig{
    position: absolute;
    left: 37%;
    top: 50.2%;
    width: 50px;
    height: 50px;
    display: block;
  }
  .main-FB{
    position: absolute;
    bottom: 13%;
    left: 41%;
    width: 60px;
    height: 50px;
    display: block;
  }
  .main-IG{
    position: absolute;
    bottom: 13%;
    left: 47.5%;
    width: 60px;
    height: 50px;
    display: block;
  }
  .main-YT{
    position: absolute;
    bottom: 13%;
    left: 53.7%;
    width: 60px;
    height: 50px;
    display: block;
  }
  .apple{
    position: absolute;
    left: 43.2%;
    bottom: 61px;
    width: 93px;
    height: 45px;
  }
  .google{
    position: absolute;
    left: 53.2%;
    bottom: 61px;
    width: 93px;
    height: 45px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
   .content-box{
    position: relative;
    width: 100%;
    height: auto;
  }
  .main-fb{
    position: absolute;
    left: 36.5%;
    top: 43.7%;
    width: 90px;
    height: 72px; 
    display: block;
  }
  .main-ig{
    position: absolute;
    left: 36.5%;
    top: 50.2%;
    width: 90px;
    height: 72px;
    display: block;
  }
  .main-FB{
    position: absolute;
    bottom: 13%;
    left: 41%;
    width: 90px;
    height: 72px;
    display: block;
  }
  .main-IG{
    position: absolute;
    bottom: 13%;
    left: 47.5%;
    width: 90px;
    height: 72px;
    display: block;
  }
  .main-YT{
    position: absolute;
    bottom: 13%;
    left: 53.7%;
    width: 90px;
    height: 72px;
    display: block;
  }
  .apple{
    position: absolute;
    left: 43.2%;
    bottom: 94px;
    width: 130px;
    height: 48px;
  }
  .google{
    position: absolute;
    left: 53.2%;
    bottom: 94px;
    width: 130px;
    height: 48px;
  }
}
@media screen and (min-width: 1930px) {
   .content-box{
    position: relative;
    width: 100%;
    height: auto;
  }
  .main-fb{
    position: absolute;
    left: 39%;
    top: 49.2%;
    width: 72px;
  }
  .main-ig{
    position: absolute;
    left: 39%;
    top: 42.6%;
    width: 72px;
  }
  .main-box,.main-box2{
    position: relative;
  }
  .main-FB{
  position: absolute;
    left: 42%;
    bottom: 45px;
    width: 72px;
      height: auto;
  }
  .main-IG{
  position: absolute;
    left: 49%;
    bottom: 45px;
    width: 72px;
      height: auto;
  }
  .main-YT{
    position: absolute;
      left: 55%;
      bottom: 45px;
    width: 72px;
      height: auto;
  }
  .apple{
    position: absolute;
    left: 43.5%;
    bottom: 188px;
    width: 190px;
    height: auto;
  }
  .google{
    position: absolute;
    left: 52.5%;
    bottom: 188px;
    width: 190px;
    height: auto;
  }
}
</style>