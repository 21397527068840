<template>
  <div class=" font-test" v-show="['zh_tw'].includes(language)">
    <div class="nav-box">
      <div class="top-menu-box">
        <div class="container top-menu">
          <!-- 左边logo -->
          <div class="left-logo">
            <el-image :src="logo" class="logo-img" lazy @click="pageHandle('/home')"></el-image>
          </div>
          <!-- 右边菜单 -->
        </div>
      </div>
    </div>
    <div class="content-box">
      <el-image class="header" :src="header" width="100%" lazy  @load="imgOnload">
        <div slot="placeholder">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>
      <div class="header-href">
        <a href="https://www.facebook.com/travelcontents" class="main-fb" target="_blank"></a>
        <a href="https://www.instagram.com/p/CdmmN5lr84J/" class="main-ig" target="_blank"></a>
      </div>
      <div class="main-box">
        <a href="https://www.facebook.com/travelcontents" class="main-FB" target="_blank"></a>
        <a href="https://www.instagram.com/travelcontents.tw/" class="main-IG" target="_blank"></a>
        <a href="https://www.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q/featured/" class="main-YT" target="_blank"></a>
      </div>
      <div class="main-box2" v-if="language != 'zh'">
        <a href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" class="apple" target="_blank"></a>
        <a href="https://play.google.com/store/apps/details?id=com.tripellet.app" class="google" target="_blank"></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showBtn: false,
      logo: require("@/assets/images/customer/MB-home.png"),
      header:require("@/assets/images/draw/MB.jpg"),
    }
  },
    mounted(){
    // 进入页面回到顶部
    this.$router.afterEach((to,from,next)=>{
      window.scrollTo(0,0)
    })
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } })
      } else {
        this.$router.push(path)
      }
    },
    openUrl(url) {
      window.open(url)
    },
    imgOnload() {
      this.showBtn = true
      this.$forceUpdate()
    }
  },
}
</script>
<style lang="scss" scoped>
.font-test {
  font-family: "GenJyuuGothic";
}
.container {
  width: 100%;
  margin: 0 auto;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
}
.button-box {
  position: absolute;
  top: 62.2%;
  left: 52.8%;
  z-index: 999;
  .el-image {
    width: 19%;
    margin: 0 5px;
  }
}
.nav-box {
  // width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}
.top-menu-box {
  background: #fff;
}
.logo-img {
  width: 30px;
  cursor: pointer;
}
.header{
  width: 100%;
  display: block;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

@media screen and (min-width: 321px) and (max-width: 410px){
  .main-fb{
    display: block;
    position: absolute;
    left: 18%;
    top: 35.7%;
    width: 85px;
    height: 70px;
  }
  .main-ig{
    display: block;
    position: absolute;
    left: 59%;
    top: 35.7%;
    width: 85px;
    height: 70px;
  }
  .main-box,.main-box2{
    position: relative;
  }
  .main-FB{
    display: block;
    position: absolute;
    left: 24%;
    bottom: 323px;
    width: 46px;
    height: 78px;
  }
  .main-IG{
    display: block;
    position: absolute;
    left: 44%;
    bottom: 323px;
    width: 46px;
    height: 78px;
  }
  .main-YT{
    position: absolute;
    left: 64%;
    bottom: 323px;
    width: 46px;
    height: 78px;
  }
  .apple{
      position: absolute;
      left: 13.5%;
      bottom: 80px;
      width: 132px;
      height: 44px;
  }
  .google{
    position: absolute;
      left: 50.5%;
      bottom: 80px;
      width: 140px;
      height: 44px;

  }
}
@media screen and (max-width: 320px){
  .main-fb{
    position: absolute;
    left: 18%;
    top: 35.8%;
    width: 73px;
    height: 55px;
  }
  .main-ig{
    position: absolute;
    left: 59%;
    top: 35.8%;
    width: 73px;
    height: 55px;

  }
  .main-box,.main-box2{
    position: relative;
  }
  .main-FB{
    position: absolute;
    left: 21%;
    bottom: 285px;
    width: 54px;
    height: 78px;
  }
  .main-IG{
    position: absolute;
    left: 44%;
    bottom: 285px;
    width: 54px;
    height: 78px;
  }
  .main-YT{
    position: absolute;
    left: 64%;
    bottom: 285px;
    width: 54px;
    height: 78px;
  }
  .apple{
    position: absolute;
      left: 14.5%;
      bottom: 70px;
      width: 108px;
      height: 44px;

  }
  .google{
    position: absolute;
      left: 52.5%;
      bottom: 70px;
      width: 108px;
      height: 44px;

  }
}
@media screen and (min-width: 411px) and (max-width: 414px){
  .main-fb{
    position: absolute;
    left: 18%;
    top: 35.5%;
    width: 100px;
    height: 75px;
  }
  .main-ig{
    position: absolute;
    left: 58%;
    top: 35.5%;
    width: 100px;
    height: 75px;
  }
  .main-box,.main-box2{
    position: relative;
  }
  .main-FB{
    position: absolute;
    left: 22%;
    bottom: 369px;
    width: 60px;
    height: 87px;
  }
  .main-IG{
  position: absolute;
    left: 44%;
    bottom: 369px;
    width: 60px;
    height: 87px;
  }
  .main-YT{
    position: absolute;
    left: 64%;
    bottom: 369px;
    width: 60px;
    height: 87px;
  }
  .apple{
    position: absolute;
      left: 13.5%;
      bottom: 95px;
      width: 140px;
      height: 44px;
  }
  .google{
    position: absolute;
      left: 52.5%;
      bottom: 95px;
      width: 140px;
      height: 44px;
  }
}

</style>